import React from 'react';
import '../ChangelogModal.css';

const ChangelogModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="changelog-modal-overlay" onClick={onClose}>
      <div className="changelog-modal" onClick={(e) => e.stopPropagation()}>
        <h2>Neue Funktionen</h2>
        <div className="changelog-content">
          <h3>Version 0.1.1 - {new Date().toLocaleDateString()}</h3>
          <ul>
            <li>Fehlerbehebungen</li>
            <li>Neuer Indikator: Globale KGV-Verhältnisse</li>
            <li>Verbesserte Chat-Erfahrung</li>
          </ul>
        </div>
        <button onClick={onClose}>Schließen</button>
      </div>
    </div>
  );
};

export default ChangelogModal;
