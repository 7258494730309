import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSubscription } from '../contexts/SubscriptionContext';
import Spinner from '../components/Spinner';

const Portfolio = () => {
  const [portfolio, setPortfolio] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showDisclaimer, setShowDisclaimer] = useState(true);
  const { subscription } = useSubscription();

  useEffect(() => {
    const fetchPortfolio = async () => {
      // Simulating data fetching
      const mockPortfolio = [
        { 
          name: 'Tesla', 
          symbol: 'TSLA', 
          positionSize: 31.20, 
          currentGain: 663.84, 
          info: 'Tesla ist ein Unternehmen für Elektrofahrzeuge und saubere Energie. Es ist bekannt für seinen innovativen Ansatz in den Bereichen nachhaltiger Transport und Energielösungen.' 
        },
        { 
          name: 'Meta Platforms', 
          symbol: 'META', 
          positionSize: 11.17, 
          currentGain: 105.28, 
          info: 'Meta Platforms, ehemals Facebook, ist ein Technologieunternehmen mit Fokus auf soziale Medien und virtuelle Realität. Es besitzt beliebte Plattformen wie Facebook, Instagram und WhatsApp.' 
        },
        { 
          name: 'iShares 20+ Year Treasury Bond ETF', 
          symbol: 'TLT', 
          positionSize: 9.13, 
          currentGain: -6.03, 
          info: 'TLT ist ein börsengehandelter Fonds, der langfristige US-Staatsanleihen abbildet. Er bietet Zugang zum US-Staatsanleihenmarkt mit Schwerpunkt auf Anleihen mit Laufzeiten von über 20 Jahren.' 
        },
        { 
          name: 'Amazon', 
          symbol: 'AMZN', 
          positionSize: 7.01, 
          currentGain: 392.14, 
          info: 'Amazon ist ein multinationales Technologieunternehmen mit Schwerpunkt auf E-Commerce, Cloud Computing und künstlicher Intelligenz. Es ist bekannt für seine breite Palette an Produkten und Dienstleistungen, einschließlich Amazon Prime und AWS.' 
        },
        { 
          name: 'Palantir Technologies', 
          symbol: 'PLTR', 
          positionSize: 6.44, 
          currentGain: 320.59, 
          info: 'Palantir Technologies ist ein Softwareunternehmen, das sich auf Big-Data-Analysen spezialisiert hat. Es bietet Lösungen für Datenintegration und -analyse für Regierungsbehörden und große Unternehmen.' 
        },
        { 
          name: 'KraneShares CSI China Internet ETF', 
          symbol: 'KWEB', 
          positionSize: 5.21, 
          currentGain: -5.55, 
          info: 'KWEB ist ein ETF, der chinesische Internet- und E-Commerce-Unternehmen abbildet. Er bietet Zugang zum wachsenden Technologiesektor und Online-Verbrauchermarkt Chinas.' 
        },
        { 
          name: 'Tencent', 
          symbol: 'TCEHY', 
          positionSize: 5.73, 
          currentGain: -4.76, 
          info: 'Tencent ist ein chinesischer multinationaler Technologiekonzern. Es ist bekannt für seine Social-Media-Plattformen, seine Spieleabteilung und verschiedene internetbezogene Dienste.' 
        },
        { 
          name: 'Mercado Libre', 
          symbol: 'MELI', 
          positionSize: 4.67, 
          currentGain: 208.86, 
          info: 'Mercado Libre ist ein E-Commerce- und Fintech-Unternehmen in Lateinamerika. Es betreibt Online-Marktplätze und bietet digitale Zahlungslösungen in mehreren Ländern der Region an.' 
        },
        { 
          name: 'Wise', 
          symbol: 'WISE', 
          positionSize: 3.66, 
          currentGain: 1.97, 
          info: 'Wise, ehemals TransferWise, ist ein globales Technologieunternehmen, das sich auf internationale Geldtransfers spezialisiert hat. Es bietet internationale Überweisungen und Multi-Währungs-Konten an.' 
        },
        { 
          name: 'Cloudflare', 
          symbol: 'NET', 
          positionSize: 3.69, 
          currentGain: 29.47, 
          info: 'Cloudflare ist ein Unternehmen für Web-Infrastruktur und Website-Sicherheit. Es bietet Content Delivery Network-Dienste, DDoS-Schutz und Internetsicherheitslösungen an.' 
        },
        { 
          name: 'SoFi Technologies', 
          symbol: 'SOFI', 
          positionSize: 3.22, 
          currentGain: -12.64, 
          info: 'SoFi Technologies ist ein Finanzunternehmen, das eine Reihe von Finanzprodukten anbietet. Es bietet Privatkredite, Studentenkreditrefinanzierung, Hypotheken und Anlagedienstleistungen an.' 
        },
        { 
          name: 'The Trade Desk', 
          symbol: 'TTD', 
          positionSize: 3.05, 
          currentGain: 84.84, 
          info: 'The Trade Desk ist ein Technologieunternehmen im Bereich digitale Werbung. Es bietet eine Self-Service-Plattform für Werbetreibende zur Erstellung und Verwaltung digitaler Werbekampagnen.' 
        },
        { 
          name: 'Shopify', 
          symbol: 'SHOP', 
          positionSize: 1.92, 
          currentGain: 161.79, 
          info: 'Shopify ist eine E-Commerce-Plattform für Online-Shops und Einzelhandelskassensysteme. Es bietet Tools und Dienstleistungen für Unternehmen zur Erstellung und Betreibung ihrer Online-Shops.' 
        },
        { 
          name: 'Airbnb', 
          symbol: 'ABNB', 
          positionSize: 1.41, 
          currentGain: -3.52, 
          info: 'Airbnb ist ein Online-Marktplatz für Unterkünfte, hauptsächlich für Ferienwohnungen. Es verbindet Gastgeber, die einzigartige Unterkünfte anbieten, mit Reisenden, die Alternativen zu traditionellen Hotels suchen.' 
        },
        { 
          name: 'Enphase Energy', 
          symbol: 'ENPH', 
          positionSize: 0.89, 
          currentGain: -17.14, 
          info: 'Enphase Energy ist ein Technologieunternehmen, das sich auf Solarenergielösungen spezialisiert hat. Es entwickelt und produziert softwaregesteuerte Heimenergielösungen für Solarstromerzeugung, Heimenergiespeicherung und webbasierte Überwachung und Steuerung.' 
        },
        { 
          name: 'Roblox', 
          symbol: 'RBLX', 
          positionSize: 0.77, 
          currentGain: -45.10, 
          info: 'Roblox ist eine Online-Spieleplattform und ein Spieleentwicklungssystem. Es ermöglicht Benutzern, Spiele zu programmieren und von anderen Benutzern erstellte Spiele zu spielen, und fördert so ein einzigartiges Ökosystem für nutzergenerierte Inhalte.' 
        },
        { 
          name: 'Lithium Americas', 
          symbol: 'LAC', 
          positionSize: 0.48, 
          currentGain: -51.24, 
          info: 'Lithium Americas ist ein Ressourcenunternehmen, das sich auf die Entwicklung von Lithiumprojekten konzentriert. Es arbeitet an der Förderung von Lithiumprojekten in Argentinien und den Vereinigten Staaten.' 
        },
        { 
          name: 'Rocket Lab USA', 
          symbol: 'RKLB', 
          positionSize: 0.36, 
          currentGain: 74.54, 
          info: 'Rocket Lab USA ist ein Luft- und Raumfahrthersteller und Anbieter von Startdiensten für Kleinsatelliten. Es entwickelt und produziert kleine Raketen und andere Raumfahrzeuge für kommerzielle, staatliche und wissenschaftliche Kunden.' 
        },
      ];

      setPortfolio(mockPortfolio);
      setIsLoading(false);
    };

    fetchPortfolio();
  }, []);

  const handleCloseDisclaimer = () => {
    setShowDisclaimer(false);
  };

  const hasActiveSubscription = subscription && subscription.subscription_status === 'active';

  return (
    <div className="portfolio-page">
      <h1>Aktienportfolio</h1>
      <p>Aktualisiert am: {new Date().toLocaleDateString('de-DE')}</p>
      <br />
      {!hasActiveSubscription && (
        <div className="upgrade-overlay">
          <div className="upgrade-message">
            <h2>Upgrade erforderlich</h2>
            <p>Um auf alle Funktionen zugreifen zu können, benötigen Sie ein aktives Abonnement.</p>
            <a href="https://projekt100x.de/mitgliedschaft-waehlen/" className="upgrade-button">Jetzt upgraden</a>
          </div>
        </div>
      )}
      <div className={`components-grid ${!hasActiveSubscription ? 'blurred' : ''}`}>
        {showDisclaimer && (
          <div className="disclaimer-box">
            <button className="close-disclaimer" onClick={handleCloseDisclaimer}>×</button>
            <p>
              <strong>Haftungsausschluss:</strong> Die hier dargestellten Informationen dienen ausschließlich zu Informationszwecken und stellen keine Anlageberatung dar. Vergangene Wertentwicklungen sind kein verlässlicher Indikator für zukünftige Ergebnisse. Investitionen in Wertpapiere bergen Risiken, einschließlich des möglichen Verlusts des eingesetzten Kapitals. Bitte konsultieren Sie einen qualifizierten Finanzberater, bevor Sie Anlageentscheidungen treffen.
            </p>
          </div>
        )}
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="portfolio-list data-card">
            {portfolio.map((stock, index) => (
              <div key={index} className="portfolio-item">
                <div className="portfolio-item-main">
                  <div className="portfolio-item-name">
                    <h2>{stock.name}</h2>
                    <span className="portfolio-item-symbol">{stock.symbol}</span>
                  </div>
                  <div className={`portfolio-item-gain ${stock.currentGain >= 0 ? 'positive' : 'negative'}`}>
                    {stock.currentGain.toFixed(2)}%
                  </div>
                </div>
                <div className="portfolio-item-details">
                  <span>Positionsgröße: {stock.positionSize.toFixed(2)}%</span>
                </div>
                <div className="portfolio-item-info">
                  <p>{stock.info}</p>
                </div>
                {stock.symbol === 'RBLX' && (
                  <div className="stock-warning">
                    <p>
                      <strong>Warnung:</strong> Es gibt einen neuen Shortseller-Bericht über Roblox mit dem Titel "Roblox: Inflated Key Metrics For Wall Street". Bitte informieren Sie sich über die möglichen Risiken. 
                      <a href="https://hindenburgresearch.com/roblox/" target="_blank" rel="noopener noreferrer">Mehr Informationen hier</a>.
                    </p>
                  </div>
                )}
                {stock.symbol === 'TLT' && (
                  <div className="stock-info">
                    <p>
                      <strong>Info:</strong> Der Anleihen-ETF bietet zusätzlich eine jährliche Rendite von ca. 3,9% mit monatlicher Ausschüttung.
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Portfolio;
