import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Landingpage.css';
import { AlertCircle, Check, FileText } from 'lucide-react';

const LandingPage = () => {
  const [hoveredStep, setHoveredStep] = useState(null);

  const steps = [
    { id: 1, status: 'success', label: 'Auth' },
    { id: 2, status: 'flaky', label: 'Login', flaky: { number: 102, attempts: 2 } },
    { id: 3, status: 'success', label: 'Verify' },
    { id: 4, status: 'flaky', label: 'Owner Role', flaky: { number: 104, attempts: 3 } },
    { id: 5, status: 'success', label: 'Complete' },
  ];

  const getStatusIcon = (status) => {
    switch (status) {
      case 'success':
        return <Check className="text-jade-400" />;
      case 'flaky':
        return <AlertCircle className="text-orange-400" />;
      default:
        return null;
    }
  };

  return (
    <div className="landing-page">
      <header className="landing-header">
        <nav className="landing-nav">
          <div className="landing-logo">Your App</div>
          <ul>
            <li><a href="#features">Features</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
      </header>

      <main>
        <section id="hero" className="hero-section">
          <h1>Revolutionize Your Market Analysis</h1>
          <p>Harness the power of AI to make informed decisions in real-time.</p>
          <Link to="/dashboard" className="cta-button">Get Started</Link>
        </section>

        <section id="features" className="features-section">
          <h2>Key Features</h2>
          <div className="feature-grid">
            <div className="feature-card">
              <div className="feature-icon">📊</div>
              <h3>Real-time Market Pulse</h3>
              <p>Stay updated with live market trends and analysis.</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">🤖</div>
              <h3>AI-Powered Insights</h3>
              <p>Leverage machine learning for predictive market analysis.</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">📈</div>
              <h3>Portfolio Management</h3>
              <p>Optimize your investments with smart portfolio tracking.</p>
            </div>
            <div className="feature-card authentication-flow-card">
              <h3>Advanced Authentication Flow</h3>
              <div className="authentication-flow">
                <div className="flow-header">
                  <FileText size={12} className="mr-[4px] text-gray-400" />
                  <span className="text-gray-900">Authentication<span className="text-gray-500">.cy.js</span></span>
                </div>
                <div className="flow-steps">
                  {steps.map((step, index) => (
                    <React.Fragment key={step.id}>
                      <div 
                        className="step"
                        onMouseEnter={() => setHoveredStep(step.id)}
                        onMouseLeave={() => setHoveredStep(null)}
                      >
                        <div className={`step-icon ${step.status === 'flaky' ? 'flaky' : ''}`}>
                          {getStatusIcon(step.status)}
                        </div>
                        {step.status === 'flaky' && hoveredStep === step.id && (
                          <div className="step-tooltip">
                            <div className="tooltip-header">
                              <span className="tooltip-number">#{step.flaky.number}</span>
                              <span className="tooltip-status">flaky</span>
                            </div>
                            <div className="tooltip-label">{step.label}</div>
                            <div className="tooltip-attempts">Attempts: {step.flaky.attempts}</div>
                          </div>
                        )}
                      </div>
                      {index < steps.length - 1 && <div className="step-connector" />}
                    </React.Fragment>
                  ))}
                </div>
              </div>
              <p>Visualize and monitor your authentication process in real-time.</p>
            </div>
          </div>
        </section>

        <section id="about" className="about-section">
          <h2>About Us</h2>
          <p>We're a team of finance experts and AI enthusiasts dedicated to revolutionizing market analysis.</p>
        </section>

        <section id="contact" className="contact-section">
          <h2>Get in Touch</h2>
          <form>
            <input type="email" placeholder="Your email" required />
            <textarea placeholder="Your message" required></textarea>
            <button type="submit" className="submit-button">Send</button>
          </form>
        </section>
      </main>

      <footer className="landing-footer">
        <p>&copy; 2023 Your App Name. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default LandingPage;
